import SearchFile from '@public/assets/iconsV3/SearchFile.svg';
import Option from '@public/assets/iconsV3/Option.svg';
import Request from '@public/assets/iconsV3/Request.svg';
import CheckFile from '@public/assets/iconsV3/CheckFile.svg';
import MaterialCheck from '@public/assets/iconsV3/MaterialCheck.svg';
import BeforeCash from '@public/assets/iconsV3/BeforeCash.svg';
import Team from '@public/assets/iconsV3/Team.svg';
import RealStore from '@public/assets/iconsV3/RealStore.svg';
import HamburgerMenu from '@public/assets/iconsV3/HamburgerMenu.svg';
import Close from '@public/assets/iconsV3/Close.svg';
import Divider from '@public/assets/iconsV3/Divider.svg';
import Store from '@public/assets/iconsV3/Store.svg';
import Buying from '@public/assets/iconsV3/Buying.svg';
import Cart from '@public/assets/iconsV3/Cart.svg';
import Setting from '@public/assets/iconsV3/Setting.svg';
import Office from '@public/assets/iconsV3/Office.svg';
import AccountOut from '@public/assets/iconsV3/AccountOut.svg';
import MeetballMenu from '@public/assets/iconsV3/MeetballMenu.svg';
import User from '@public/assets/iconsV3/User.svg';
import Language from '@public/assets/iconsV3/Language.svg';
import Frame from '@public/assets/iconsV3/Frame.svg';
import Project from '@public/assets/iconsV3/Project.svg';
import Statistics from '@public/assets/iconsV3/Statistics.svg';
import SignOut from '@public/assets/iconsV3/SignOut.svg';
import Noti from '@public/assets/iconsV3/Noti.svg';
import NotiNew from '@public/assets/iconsV3/NotiNew.svg';
import NotiLightOn from '@public/assets/iconsV3/NotiLightOn.svg';
import NotiLightOff from '@public/assets/iconsV3/NotiLightOff.svg';
import CheckCircle from '@public/assets/iconsV3/CheckCircle.svg';
import CheckCircleBlue from '@public/assets/iconsV3/CheckCircleBlue.svg';
import CheckCircleEmpty from '@public/assets/iconsV3/CheckCircleEmpty.svg';
import ArrowRight from '@public/assets/iconsV3/ArrowRight.svg';
import ArrowDown from '@public/assets/iconsV3/ArrowDown.svg';
import ArrowUp from '@public/assets/iconsV3/ArrowUp.svg';
import ArrowLeft from '@public/assets/iconsV3/ArrowLeft.svg';
import ArrowRightDouble from '@public/assets/iconsV3/ArrowRightDouble.svg';
import ArrowLeftDouble from '@public/assets/iconsV3/ArrowLeftDouble.svg';
import CheckSquare from '@public/assets/iconsV3/CheckSquare.svg';
import CheckSquareBlue from '@public/assets/iconsV3/CheckSquareBlue.svg';
import CheckSquareEmpty from '@public/assets/iconsV3/CheckSquareEmpty.svg';
import RadioCircle from '@public/assets/iconsV3/RadioCircle.svg';
import RadioCircleBlue from '@public/assets/iconsV3/RadioCircleBlue.svg';
import RadioCircleBlack from '@public/assets/iconsV3/RadioCircleBlack.svg';
import RadioCircleEmpty from '@public/assets/iconsV3/RadioCircleEmpty.svg';
import RadioCircleEmptyBlack from '@public/assets/iconsV3/RadioCircleEmptyBlack.svg';
import UploadFile from '@public/assets/iconsV3/UploadFile.svg';
import AlertCircle from '@public/assets/iconsV3/AlertCircle.svg';
import SearchThings from '@public/assets/iconsV3/SearchThings.svg';
import Pipe from '@public/assets/iconsV3/Pipe.svg';
import Valve from '@public/assets/iconsV3/Valve.svg';
import Flange from '@public/assets/iconsV3/Flange.svg';
import Fitting from '@public/assets/iconsV3/Fitting.svg';
import Calendar from '@public/assets/iconsV3/Calendar.svg';
import AlertTriangle from '@public/assets/iconsV3/AlertTriangle.svg';
import Delete from '@public/assets/iconsV3/Delete.svg';
import Edit from '@public/assets/iconsV3/Edit.svg';
import DownloadCircle from '@public/assets/iconsV3/DownloadCircle.svg';
import AlertStop from '@public/assets/iconsV3/AlertStop.svg';
import AlertMore from '@public/assets/iconsV3/AlertMore.svg';
import Pj1RequestQuotation from '@public/assets/iconsV3/Pj1RequestQuotation.svg';
import Pj2OnBidding from '@public/assets/iconsV3/Pj2OnBidding.svg';
import Pj3PlacePO from '@public/assets/iconsV3/Pj3PlacePO.svg';
import Pj4OnShipping from '@public/assets/iconsV3/Pj4OnShipping.svg';
import Pj5Complete from '@public/assets/iconsV3/Pj5Complete.svg';
import Pj6RequestWrite from '@public/assets/iconsV3/Pj6RequestWrite.svg';
import Pj7Expiration from '@public/assets/iconsV3/Pj7Expiration.svg';
import Pj8RejectQuotation from '@public/assets/iconsV3/Pj8RejectQuotation.svg';
import Pj9SubmComplete from '@public/assets/iconsV3/Pj9SubmComplete.svg';
import Pj10RequestModification from '@public/assets/iconsV3/Pj10RequestModification.svg';
import Pj11UnderOrder from '@public/assets/iconsV3/Pj11UnderOrder.svg';
import ProjectDetail from '@public/assets/iconsV3/ProjectDetail.svg';
import ProjectDetailBlue from '@public/assets/iconsV3/ProjectDetailBlue.svg';
import Filter from '@public/assets/iconsV3/Filter.svg';
import Add from '@public/assets/iconsV3/Add.svg';
import AddPerson from '@public/assets/iconsV3/AddPerson.svg';
import AddOffice from '@public/assets/iconsV3/AddOffice.svg';
import RangeWave from '@public/assets/iconsV3/RangeWave.svg';
import Indicator from '@public/assets/iconsV3/Indicator.svg';
import Chat from '@public/assets/iconsV3/Chat.svg';
import DeleteAll from '@public/assets/iconsV3/DeleteAll.svg';
import LogoBlack from '@public/assets/iconsV3/LogoBlack.svg';
import Loading from '@public/assets/iconsV3/Loading.svg';
import Click from '@public/assets/iconsV3/Click.svg';
import TextCopy from '@public/assets/iconsV3/textCopy.svg';
import Book from '@public/assets/iconsV3/BooK.svg';
import Category from '@public/assets/iconsV3/Category.svg';
import CategoryInput from '@public/assets/iconsV3/CategoryInput.svg';

export const icons = {
  'search-file': SearchFile,
  option: Option,
  request: Request,
  'check-file': CheckFile,
  'material-check': MaterialCheck,
  'before-cash': BeforeCash,
  team: Team,
  'real-store': RealStore,
  'hamburger-menu': HamburgerMenu,
  close: Close,
  divider: Divider,
  store: Store,
  buying: Buying,
  cart: Cart,
  setting: Setting,
  office: Office,
  'account-out': AccountOut,
  'meetbal-menu': MeetballMenu,
  user: User,
  language: Language,
  frame: Frame,
  project: Project,
  statistics: Statistics,
  'sign-out': SignOut,
  noti: Noti,
  'noti-new': NotiNew,
  'noti-light-on': NotiLightOn,
  'noti-light-off': NotiLightOff,
  'check-circle': CheckCircle,
  'check-circle-blue': CheckCircleBlue,
  'check-circle-empty': CheckCircleEmpty,
  'arrow-right': ArrowRight,
  'arrow-down': ArrowDown,
  'arrow-up': ArrowUp,
  'arrow-left': ArrowLeft,
  'arrow-right-double': ArrowRightDouble,
  'arrow-left-double': ArrowLeftDouble,
  'check-square': CheckSquare,
  'check-square-blue': CheckSquareBlue,
  'check-square-empty': CheckSquareEmpty,
  'radio-circle': RadioCircle,
  'radio-circle-blue': RadioCircleBlue,
  'radio-circle-black': RadioCircleBlack,
  'radio-circle-empty': RadioCircleEmpty,
  'radio-circle-empty-black': RadioCircleEmptyBlack,
  'upload-file': UploadFile,
  'alert-circle': AlertCircle,
  'search-things': SearchThings,
  pipe: Pipe,
  valve: Valve,
  flange: Flange,
  fitting: Fitting,
  calendar: Calendar,
  'alert-triangle': AlertTriangle,
  delete: Delete,
  edit: Edit,
  'download-circle': DownloadCircle,
  'alert-stop': AlertStop,
  'alert-more': AlertMore,
  'pj1-request-quotation': Pj1RequestQuotation,
  'pj2-on-bidding': Pj2OnBidding,
  'pj3-place-po': Pj3PlacePO,
  'pj4-on-shipping': Pj4OnShipping,
  'pj5-complete': Pj5Complete,
  'pj6-request-write': Pj6RequestWrite,
  'pj7-expiration': Pj7Expiration,
  'pj8-reject-quotation': Pj8RejectQuotation,
  'pj9-sumb-complete': Pj9SubmComplete,
  'pj10-request-modification': Pj10RequestModification,
  'pj11-under-order': Pj11UnderOrder,
  'project-detail': ProjectDetail,
  'project-detail-blue': ProjectDetailBlue,
  filter: Filter,
  add: Add,
  'add-person': AddPerson,
  'add-office': AddOffice,
  'range-wave': RangeWave,
  indicator: Indicator,
  chat: Chat,
  'delete-all': DeleteAll,
  'logo-black': LogoBlack,
  loading: Loading,
  click: Click,
  'text-copy': TextCopy,
  book: Book,
  category: Category,
  'category-input': CategoryInput,
};

export type IconName = keyof typeof icons;
