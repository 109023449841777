export const primary = {
  blue: '#0D4FDE',
  gray: '#222222',
};

// blue scale
export const blue = {
  blue1: '#EDF2FF',
  blue2: '#C1D2FC',
  blue3: '#4980F6',
  blue4: '#0038B2',
  blue5: '#01277A',
};

// gray scale
export const gray = {
  gray1: '#F6F6F6',
  gray2: '#EEEEEE',
  gray3: '#E2E2E2',
  gray4: '#CBCBCB',
  gray5: '#AFAFAF',
  gray6: '#808080',
  gray7: '#535353',
};

// 에러, 경고와 같은 부정적인 상황에 사용
export const red = {
  red1: '#FFE9E9',
  red2: '#FF9B9B',
  red3: '#FF5C5C',
  red4: '#DF1D1D',
  red5: '#BC0000',
};

export const blueGray = {
  blueGray1: '#F1F4FA',
};

// 성공
export const green = {
  green1: '#D9F4D2',
  green2: '#A6DA98',
  green3: '#34963C',
  green4: '#236F2A',
  green5: '#113D15',
};

export const yellow = {
  yellow1: '#FFF3D6',
  yellow2: '#FFDB96',
  yellow3: '#FFC670',
  yellow4: '#FFB31E',
  yellow5: '#E98C00',
};

export const purple = {
  purple1: '#E6E1FE',
  purple3: '#7A69EE',
  purple4: '#5B4CCC',
  purple5: '#2A218A',
};

export const white = '#FFFFFF';
export const black = '#000000';
